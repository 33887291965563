<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-04-06 09:51:10
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-31 15:46:14
-->
<template>
  <div class="">
    <a-modal :maskClosable="false" :visible="visible" :footer="null" @cancel="handleCancel" :width="600">
      <video v-if="type=='video'" style="width: 100%"  controls :src="url"></video>
      <img v-else alt="" style="width: 100%" :onerror="defaultImg" :src="url" />
    </a-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        defaultImg:'this.src="'+ require('/src/assets/errorimg.png')+'"',
      }
    },
    props: ["visible", "url", "type"],
    methods: {
      handleCancel() {
        this.$emit("update:visible", false)
      }
    },
    created() {

    }
  }
</script>

<style lang="less" scoped>
</style>
