/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2020-12-21 09:54:19
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-10 18:36:36
 */
import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { VueAxios } from './axios'
import { notification,modal} from 'ant-design-vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
//
// const baseURL = 'https://dealer.5ulm.cn'//process.env.VUE_APP_API_BASE_URL
// const baseURL = 'https://dealer.5uplus.com'//process.env.VUE_APP_API_BASE_URL
//const baseURL = 'http://127.0.0.1:30080'//process.env.VUE_APP_API_BASE_URL
const baseURL = process.env.VUE_APP_API_BASE_URL
// 创建 axios 实例
const service = axios.create({
  // baseURL: baseURL, // api base_url
  timeout: 120000 // 请求超时时间
})
export const pureAxios = axios.create({
  // baseURL: baseURL, // api base_url
  timeout: 120000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: '未认证',
        description: '认证失败，请重新登录'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// 请求拦截器
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers['authorization-distributor'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  config.headers['Cache-Control']='no-cache'
  config.headers['Pragma']='no-cache'
  return config
}, err)

pureAxios.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers['authorization-distributor'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  config.headers['Cache-Control']='no-cache'
  config.headers['Pragma']='no-cache'
  return config
}, err)

// 响应拦截器
service.interceptors.response.use((response) => {
  let data=response.data
  if(data.code==200){
    return data
  }else{
    notification.error({
      message: '提示',
      description: data.message
    })
    return Promise.reject(data)
  }
  return response.data
}, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios,
  baseURL,
}
