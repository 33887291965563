<template>
  <div class="">
    <a-radio-group v-if="type=='radio'" v-model="value2" :options="options" @change="changeValue" />
    <a-checkbox-group v-if="type=='checkbox'" v-model="value2" :options="options" @change="changeValue" />
  </div>
</template>

<script>
  import * as selectOptions from "./selectOptions.js"
  export default {
    data() {
      return {
        options: [],
        value2: [],
        value3: []
      }
    },
    props: ["type", "field", "value"],
    watch: {
      value(newVal, oldVal) {
        if (this.type == "checkbox") {
          this.value2 = []
          let tmpArr = newVal ? newVal.split(",") : []
          tmpArr.forEach(x => {
            this.value2.push(x)
          })
        } else {
          this.value2 = newVal
        }

      }
    },
    methods: {
      changeValue(value) {
        if (this.type == "checkbox") {
          if (value.indexOf('全选')>=0) {
            if(this.value3.indexOf("全选")>=0){
              value.splice(value.indexOf("全选"),1)
            }else{
              value = this.options.map(x => x.value)
            }
          } else {
            if (this.value3.indexOf("全选") >= 0) {
              value = []
            } else if (value.length == this.options.length - 1) {
              let index = value.indexOf('全选')
              index >= 0 ? value.splice(index, 1) : value.push("全选")
            }
          }
          this.value2 = value
          this.value3 = this.value2.join(",").split(",")
          this.$emit("update:value", this.value2.join(','))
        } else {
          this.$emit("update:value", this.value2)
        }
        this.$emit("change")

      }
    },
    created() {
      this.options = selectOptions[this.field]
      this.value2=this.value
    }
  }
</script>

<style lang="scss" scoped>
</style>
