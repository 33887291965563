<template>
  <div class="">
    <a-select :disabled="disabled||false" :placeholder="placeholder?placeholder:'请选择'" v-model="value2" :mode="mode" style="width: 100%" allowClear @change="changeValue" :getPopupContainer="node=>node?node.parentNode:document.body">
      <a-select-option v-for="item in options" :key="item.id" :value="id?item.itemCode:item.value">{{id?item.itemName:item.label}}</a-select-option>
    </a-select>
  </div>
</template>

<script>
  import * as selectOptions from "./selectOptions.js"
  export default {
    data() {
      return {
        options: [],
        value2:[],
      }
    },
    props: ["placeholder", "id", "mode", "field", "value","disabled"],
    watch: {
      value(newVal, oldVal) {
        if (this.mode == "tags"||this.mode=='multiple') {
          this.value2 = []
          let tmpArr = newVal ? newVal.split(",") : []
          tmpArr.forEach(x => {
            this.value2.push(x)
          })
        } else {
          this.value2 = newVal
        }
      }
    },
    methods: {
      getDicById() {
        this.axios.get(`/api/base/system/sysItems/list?itemId=${this.id}&pageSize=100&pageNumber=1`).then(res => {
          this.options = res.body.records
        }).catch(err => {})
      },
      changeValue(value) {
        
        if (this.mode == "tags"||this.mode=='multiple') {
          this.value2 = value
          this.$emit("update:value", typeof this.value2=='undefined'?'':this.value2.join(','))
        } else {
          this.$emit("update:value", typeof this.value2=='undefined'?'':this.value2)
        }
        this.$emit("change")
      }
    },
    created() {
      if (this.id) {
        this.getDicById()
      } else if (this.field) {
        this.options = selectOptions[this.field]
        if(this.value!=undefined){
          if(this.mode=="tag"||this.mode=='multiple'){
            this.value2=this.value.split(",")
          }else{
            
            this.value2=this.value
          }
        }
        
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
