<template>
  <div class="upload">
    <div class="box" v-for="(item,index) in images" :key="index">
      <input type="file" :ref="'file'+index" :multiple="true" v-bind="$attrs" style="display:none"  @change="upload(index,$event)" />
      <!-- <transition name="el-fade-linear"> -->
      <div>
        <div class="uploaded" v-show="item" @mouseenter="()=>{$set(hovers,index,true)}">
          <img v-if="type=='image'" :src="item" alt="" class="img" />
          <video v-if="type=='video'" :src="item" alt="" class="img" />
          <div v-if="type=='file'"  alt="" class="img">
            <img style="width: 100%; height: 100%" src="https://img2.baidu.com/it/u=2017657713,2692698680&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
          </div>
        </div>
      </div>
      <!-- </transition> -->
      <div v-show="!item" class="uploaded" @click.prevent="triggerUpload(index)">
        <!-- <i style="font-size: 24px;" class="el-icon-plus" /> -->
        <a-icon type="plus" style="font-size: 24px;" />
      </div>
      <div class="hover" v-if="hovers[index]" @mouseleave="()=>{$set(hovers,index,false)}">
        <!-- <i class="el-icon-view" @click.prevent="viewImage(index)"></i> -->
        <a-icon type="eye"  @click.prevent="viewImage(index)" />
        <a-icon type="edit" v-if="!disabled" @click.prevent="triggerUpload(index)" />
        <a-icon type="delete" v-if="!disabled" @click.prevent="delImage(index)" />
        <!-- <i class="el-icon-edit" @click.prevent="triggerUpload(index)"></i> -->
        <!-- <i class="el-icon-delete" @click.prevent="delImage(index)"></i> -->
      </div>

    </div>
    <div class="modal" v-if="visible" @click.prevent="()=>{visible=false}">
      <div class="content" @click.stop.prevent="()=>{return false}">
        <img v-if="type=='image'" :src="curImage" alt="" class="img" />
        <video v-if="type=='video'" :src="curImage" alt="" class="img" controls />
        <div v-if="type=='file'" :src="curImage" alt="" class="img"></div>
      </div>
    </div>
  </div>
</template>

<script>
  const qiniu = require('qiniu-js')
  export default {
    name: 'Upload',
    data() {
      return {
        hovers: [],
        images: [''],
        visible: false,
        curImage: '',
        accept: '',
        multiple:false,
      }
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      size: {
        type: Number,
        default: 1
      },
      type: {
        type: String,
        default: 'image'
      },
      disabled: {
          type: Boolean,
          default: false
      }
    },
    watch: {
      size(val){
        this.multiple=val>1
      },
      value(val) {
        if (val) {
          let tmpArr = val.split(',')
          this.images = tmpArr
          this.hovers = []
          this.images.forEach(x => {
            this.hovers.push(false)
          })
          if (this.images[this.images.length - 1] && tmpArr.length < this.size&&!this.disabled) {
            this.images.push('')
            this.hovers.push(false)
          }
        } else {
          this.images = ['']
        }
      }
    },
    methods: {
      delImage(index) {
        if (this.images.length > 1) {
          this.images.splice(index, 1)
          this.hovers.splice(index, 1)
          let tmp = this.images.filter(x => x)
          this.$emit('update:value', tmp.join(','))
        } else {
          this.images.splice(index, 1, '')
          this.hovers.splice(index, 1, false)
          let tmp = this.images.filter(x => x)
          this.$emit('update:value', tmp.join(','))
        }

      },
      viewImage(index) {
        if (this.type == 'file'){
          window.open(this.images[index], '_blank');
        }else{
          this.curImage = this.images[index]
          this.visible = true
        }
      },
      triggerUpload(index) {
        if(this.disabled){
            return;
        }
        if (this.type == 'image') {
          this.accept = "image/png, image/jpeg, image/gif"
        } else if (this.type == 'video') {
          this.accept = 'video/mp4'
        }else if(this.type == 'file') {
          this.accept = "application/vnd.ms-powerpoint,application/msword, application/pdf"
        }
        this.$refs['file' + index][0].click();
      },
      upload(index, e) {
        let files = [...e.target.files] //e.target.files是一个类数组
        if (files.length + this.images.length > this.size) {
          files = files.slice(0, this.size - this.images.length + 1)
        }
        let filenames = []
        for (let i = 0; i < files.length; i++) {
          if (this.type == 'image' && files[i].type.indexOf('image/') < 0) {
            return this.$message.error("请上传图片")
          }
          if (this.type == 'video' && files[i].type.indexOf('video/mp4') < 0) {
            return this.$message.error("请上传mp4格式视频")
          }
          if (this.type == 'file' && files[i].type.indexOf('application/') < 0 && files[i].type.indexOf('image/') < 0) {
            return this.$message.error("请上传图片或者doc、wps、pdf格式文件")
          }
          filenames.push(files[i].name)
        }
        if (files) {
          let formData = new FormData();
          formData.append('filenames',filenames.join(','))
          this.axios.post(`/api/upload/qiniu/token`, formData).then(res => {
            let tmp = res.body
            let domain = tmp.url
            let config = {
              useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
              disableStatisticsReport: false,
              region: null // 根据具体提示修改上传地区,当为 null 或 undefined 时，自动分析上传域名区域 qiniu.region.z2
            };
            let putExtra = {
              mimeType: this.accept
            }
            for (let j = 0; j < tmp.data.length; j++) {
              let observable = qiniu.upload(files[j], tmp.data[j].key, tmp.data[j].token,
                putExtra, config)
              observable.subscribe({
                next: result => {
                  // 主要用来展示进度
                },
                error: errResult => {
                  // 失败报错信息
                  this.$message.error("上传报错");
                  e.target.value = ''
                },
                complete: res2 => {
                  // 接收成功后返回的信息
                  let url = domain + res2.key
                  let tmpArr = this.value ? this.value.split(',') : ['']
                  tmpArr.splice(index + j, j == 0 ? 1 : 0, url)
                  this.$emit('update:value', tmpArr.join(','))
                  e.target.value = ''
                }
              });
            }


          }).catch(err => {})
        }
      },
    },
    created() {
      this.images = this.value ? this.value.split(',') : ['']
    }
  }
</script>

<style lang="less" scoped>
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;

    .content {
      width: 800px;
      height: 600px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      overflow: hidden;

      .img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .box {
    width: 80px;
    height: 80px;
    position: relative;
    border: 1px solid #ddd;
    padding: 4px;
    border-radius: 4px;
    color: #ddd;
    margin: 5px;
    display: inline-block;
    overflow: hidden;

    .uploaded {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;


    }

    .img {
      max-width: 100%;
      max-height: 100%;
    }

    .hover {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 1;

      i {
        padding: 4px;
      }
    }
  }
</style>
