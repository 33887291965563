/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-04 10:57:03
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-18 16:02:54
 */
export const groundingStatus = [
  {id:0,value:null,label:'全部'},
  {id:1,value:1,label:'上架'},
  {id:2,value:2,label:'下架'},
  // {id:3,value:3,label:"强制下架"},
]

export const productStatus=[
	{id:1,value:1,label:'未处理'},
  {id:2,value:2,label:'待审核'},
  {id:3,value:3,label:'审核通过'},
  {id:4,value:4,label:'审核失败'}
]

export const flagSendStatus=[
	{id:1,value:1,label:'已推送'},
  {id:2,value:0,label:'未推送'},
]

export const dealerType=[
	{id:1,value:1,label:'中心店'},
  {id:2,value:2,label:'核心店'},
  {id:3,value:3,label:'一般店'},
  {id:4,value:4,label:'核心店（预备）'},
]

export const cooperateStatus=[
	{id:1,value:1,label:'合作中'},
  {id:2,value:2,label:'合作终止'},
]

export const RebatePolicyType=[
	{id:1,value:1,label:'折扣'},
  {id:2,value:2,label:'政策'},
  {id:3,value:3,label:'折扣&政策 2选1'},
  {id:4,value:4,label:'折扣、政策'},
]

export const IntegralType=[
	{id:1,value:true,label:'是'},
  {id:2,value:false,label:'否'},
]

export const reviewStatus=[
  {id:1,value:true,label:'未处理'},
  {id:2,value:false,label:'待审核'},
  {id:3,value:false,label:'审核通过'},
  {id:4,value:false,label:'拒绝'},
]

export const sourceType=[
  {id:1,value:1,label:'代客要货'},
  {id:2,value:2,label:'自主要货'},
  {id:3,value:3,label:'其他'},
]

export const shippenStatus=[
  {id:5,value:5,label:'部分发货'},
  {id:6,value:6,label:'已发货（全部发货）'},
]

export const auditedStatus=[
  {id:3,value:3,label:'审核拒绝'},
  {id:4,value:4,label:'已审核'},
]

export const orderStatus=[
  {id:0,value:0,label:'待审核'},
  {id:1,value:1,label:'已作废'},
  {id:2,value:2,label:'已取消'},
  {id:3,value:3,label:'审核拒绝'},
  {id:4,value:4,label:'已审核'},
  {id:5,value:5,label:'部分发货'},
  {id:6,value:6,label:'已发货（全部发货）'},
  {id:7,value:7,label:'已完成（用户签收）'},
  {id:8,value:8,label:'已评价'},
  {id:9,value:9,label:'草稿'},
]

export const shipStatus=[
  {id:0,value:0,label:'待发货'},
  {id:1,value:1,label:'已发货'},
  {id:2,value:2,label:'已收货'},
]

export const deliveryStatue=[
  {id:1,value:1,label:'已发货'},
  {id:2,value:2,label:'已收货'},
]

export const followUpModes=[
  {id:1,value:1,label:'登门拜访'},
  {id:2,value:2,label:'微信'},
  {id:3,value:3,label:'电话'},
]

export const cusAdvanceOrder=[
  {id:0,value:0,label:'未申请'},
  {id:1,value:1,label:'已申请'},
  {id:2,value:2,label:'已成交'},
  // {id:3,value:3,label:"已失效"},
  {id:4,value:4,label:'丢单'},
  {id:4,value:5,label:'无效'},
  {id:6,value:6,label:'过期'},
]

export const advanceOrder=[
  {id:1,value:1,label:'已申请'},
  {id:2,value:2,label:'已成交'},
  // {id:3,value:3,label:"已失效"},
  {id:4,value:4,label:'丢单'},
  {id:5,value:5,label:'已无效'},
  {id:6,value:6,label:'过期'},
]

export const cusOrderType=[
  {id:1,value:1,label:'零售'},
  {id:2,value:2,label:'项目'}
]

export const registrationStatus=[
  {id:1,value:1,label:'首次申请'},
  {id:2,value:2,label:'二次申请'},
  {id:3,value:3,label:'申请成功'}
]

export const cusRegistrationStatus=[
  {id:0,value:0,label:'未申请'},
  {id:1,value:1,label:'首次申请'},
  {id:2,value:2,label:'二次申请'},
  {id:3,value:3,label:'申请成功'}
]

export const receivingUnit=[
  {id:1,value:1,label:'个人'},
  {id:2,value:2,label:'公司'},
]

export const dealType = [
  {id:1,value:1,label:'支付'},
  {id:2,value:2,label:'提现'},
  {id:3,value:3,label:'充值'},
  {id:4,value:4,label:'退款'},
  {id:5,value:5,label:'订单结算'},
  {id:6,value:6,label:'订单取消'},
  {id:7,value:7,label:'提现取消/驳回'},
  {id:8,value:8,label:'订单退款'},
  {id:9,value:9,label:'平台操作'},
  {id:10,value:10,label:'还款'}
]

export const amountType = [
  {id:1,value:1,label:'收入'},
  {id:2,value:2,label:'支出'}
]

export const rebateDealType = [
  {id:1,value:1,label:'支付'},
  {id:2,value:2,label:'充值'},
  {id:3,value:3,label:'订单取消'},
  {id:4,value:4,label:'订单关闭'},
  {id:5,value:5,label:'平台操作'},
  {id:6,value:6,label:'订单修改时退回'},
  {id:7,value:7,label:'订单删除'},
  {id:8,value:8,label:'审核拒绝'},
  {id:9,value:9,label:'折扣过期'},
  {id:10,value:10,label:'折扣延时'},
  {id:11,value:11,label:'折扣删除'},
  {id:12,value:12,label:'发货单取消'},
  {id:13,value:13,label:'发货单驳回'}
]

export const limitDealType = [
  {id:1,value:1,label:'支付'},
  {id:2,value:2,label:'充值'},
  {id:3,value:3,label:'订单取消'},
  {id:4,value:4,label:'订单关闭'},
  {id:5,value:5,label:'平台操作'},
  {id:6,value:6,label:'订单修改时退回'},
  {id:7,value:7,label:'订单删除'},
  {id:8,value:8,label:'审核拒绝'},
  {id:9,value:9,label:'额度过期'},
  {id:10,value:10,label:'额度禁用'},
  {id:11,value:11,label:'额度删除'},
  {id:12,value:12,label:'发货单取消'},
  {id:13,value:13,label:'发货单驳回'},
  {id:14,value:14,label:'额度返审'},
  {id:15,value:15,label:'延期充值'},
  {id:16,value:16,label:'还款'}
]