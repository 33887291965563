<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-20 17:04:01
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-10 14:37:47
-->
<template>
  <div class="check-order-info">
    <a-modal v-model="visible" title="未出库详情" width="80%" style="top: 8px" :footer="null" :maskClosable="false">
      <a-spin :spinning="spinning">
        <a-divider orientation="left">商品信息</a-divider>
        <div class="product_info">
          <a-table :data-source="detail" :rowKey="(record) => record.id" :pagination="false" bordered>
            <a-table-column title="商品图片" data-index="proPicture" align="center" width="10%">
              <template slot-scope="text">
                <ZoomMedia style="height: 20px" :value="text"></ZoomMedia>
              </template>
            </a-table-column>
            <a-table-column title="商品名称" data-index="proName" align="center" width="20%"></a-table-column>
            <a-table-column title="分类" data-index="proOrgCodeName" align="center" width="15%"></a-table-column>
            <a-table-column title="品牌" data-index="proBrandName" align="center" width="10%"></a-table-column>
            <a-table-column title="型号" data-index="model" align="center" width="15%"></a-table-column>
            <a-table-column title="购买数量" data-index="purchaseNum" align="center" width="10%"></a-table-column>
            <a-table-column title="已出库数量" data-index="sendNum" align="center" width="10%"></a-table-column>
            <a-table-column title="未出库数量" data-index="notSendNum" align="center" width="10%"></a-table-column>
          </a-table>

        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import {
  selectNoShipProByOrderCode
} from '../api/OrderInfoApi'
export default {
  name: '',
  data() {
    return {
      visible: false,
      tableData: [],
      detail: [],
      dealerRebateUsedDetails: [],
      orderShipInfoList: [],
      checkedTabs: 'base_info',
      orderId: '',
      taskId: '',
      statusRadio: 4,
      audit_status: 4,
      handel: 'check',
      rejectReason: '',
      rebateVisible: false,
      imageUrl: '',
      loading:false,
      contractTypeList: [],
      root:'',
      spinning:false
    }
  },

  components: {

  },

  computed: {},

  created() {},

  filters: {
    status(type) {
      const typeMap = {
        0: '待审核',
        1: '已关闭',
        2: '已取消',
        3: '审核拒绝',
        4: '已审核待发货',
        5: '部分发货',
        6: '已发货（全部发货）',
        7: '已完成（用户签收）',
        8: '已评价',
        9: '草稿',
      }
      return typeMap[type]
    },
  },

  methods: {
    isShow(row, name,root) {
      this.spinning = true
      this.root = root
      let timerId = setInterval(() => {
        clearInterval(timerId)
        this.visible = true
      }, 500)
      this.orderCode = row.orderCode
      selectNoShipProByOrderCode(this.orderCode).then((res) => {
        this.detail = res.body
        this.spinning = false
      }).finally(()=>{
        this.spinning = false
      })

    },


  },
}
</script>

<style lang='scss' scoped>
.order_title,
.row {
  display: flex;
  justify-content: space-between;
}
.order_title {
  margin-bottom: 10px;
}
.row {
  color: rgba(0, 0, 0, 0.8);
}
.order_code {
  font-size: 20px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
}
.split-line {
  height: 13px;
  background: #ececec;
  margin: 15px 0;
  width: 105%;
  margin-left: -24px;
}
.line {
  width: 105%;
  margin: 15px 0;
  height: 1px;
  background: #e9e9e9;
  margin-left: -24px;
}
.client_info {
  &_row {
    flex-wrap: wrap;
  }
  &_title {
    font-size: 16px;
    color: #333333;
    line-height: 10px;
  }
}
.product_info_title {
  margin-bottom: 12px;
  font-size: 16px;
  color: #333333;
}
.footer-bts {
  text-align: right;
}
.discount {
  text-align: right;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.hyperlinks {
  font-size: 12px;
  color: #888888;
  margin-left: 5px;
}
.hyperlinks:hover {
  color: #4398fa;
}
.fonnter-btns {
  text-align: right;
}
.checkbox_text {
  margin-right: 40px;
}
.line_spacing {
  margin-bottom: 10px;
}
</style>