/*
 * @Description: AKJERRT
 * @Date: 2022-07-26 11:14:15
 * @LastEditTime: 2022-08-15 17:00:34
 * @FilePath: \distributor\src\store\modules\common.js
 */
import { getBillRemind } from "../../components/initModal/api/api"
import { getUpgradeRemind } from "../../components/initCongirmModal/api/api"
const state = {
  loginModal: false,
  routerLink: false,
  modalData: 0,
  confirmModal: false,
  confirmModalData: {}
}
const getters = {
}
const mutations = {
  SET_CONFIRM_MODAL(state, data) {
    state.confirmModal = true
    state.confirmModalData = data.content
  },
  SET_CONFIRM_MODAL_ClOSE(state) {
    state.confirmModal = false
  },
  SET_LOFIN_MODAL(state, value) {
    state.loginModal = value
  },
  SET_LOFIN_MODAL_OPEN(state,data) {
    state.loginModal = true
    state.routerLink = true
    state.modalData = data
    console.log(JSON.parse(JSON.stringify(state.modalData)), "state.modalData");
  },
  SET_LOFIN_MODAL_ClOSE(state) {
    state.routerLink = false
    state.loginModal = false
  },
}
const actions = {
  getInitConfirm({ commit }, data) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        getUpgradeRemind().then(({ code, body, message }) => {
          if (code === 200 && body.flag) {
            commit('SET_CONFIRM_MODAL', body)
          }
        })
      }, 3000)
    })
  },
  getInitBill({ commit }, data) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        getBillRemind().then(({ code, body, message }) => {
          console.log('   SET_LOFIN_MODAL_OPEN   ',body)
          if (code === 200 && body > 0) {
            commit('SET_LOFIN_MODAL_OPEN',body)
          } else {
            commit('SET_LOFIN_MODAL_ClOSE')
          }
        })
      }, 2000)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
