/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2020-12-21 09:54:19
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-13 15:27:07
 */
// ie polyfill
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import {VueAxios} from './utils/request'
import Vuex from 'vuex'
import VueJsonp from 'vue-jsonp'
import bootstrap from './core/bootstrap'
import './core/lazy_use'
import './permission' // permission control 权限控制
import './utils/filter' // global filter  全局过滤器
// ant的样式
import 'ant-design-vue/dist/antd.less'
import i18n from './locales'
import './components/global.less'
import {Dialog} from '@/components'
//注册自定义全局控件
import DictSelect from '@/views/components/DictSelect.vue'
import ZoomMedia from '@/views/components/ZoomMedia.vue'
import PCD from '@/views/components/PCD.vue'
import QiniuUpload from '@/views/components/QiniuUpload.vue'
import RadioChk from '@/views/components/RadioChk.vue'
import DateRange from '@/views/components/DateRange.vue'
import TimePicker from '@/views/components/TimePicker.vue'
import DatePicker from '@/views/components/DatePicker.vue'
import RangePicker from '@/views/components/RangePicker.vue'
import MonthPicker from '@/views/components/MonthPicker.vue'
import DownLoad from '@/views/components/DownLoad.vue'
import HighchartsVue from 'highcharts-vue'
import VueQuillEditor from 'vue-quill-editor'
import Print from 'vue-print-nb'
import VueDraggableResizable from 'vue-draggable-resizable'
import OrderList from '@/views/components/OrderList.vue'
import OrderSearch from '@/views/components/OrderSearch.vue'
import bus from '@/views/components/eventBus'
import { formatMoney } from './utils/util'
Vue.filter("formatMoney", formatMoney)

Vue.prototype.$bus = bus

 Vue.use(Print)

 Vue.component('vue-draggable-resizable', VueDraggableResizable)

// require styles 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)
Vue.use(Vuex);
Vue.use(VueJsonp)
// mock
// import './mock'
Vue.component('DictSelect', DictSelect)
Vue.component('ZoomMedia', ZoomMedia)
Vue.component('PCD', PCD)
Vue.component('QiniuUpload', QiniuUpload)
Vue.component('RadioChk', RadioChk)
Vue.component('DateRange', DateRange)
Vue.component('TimePicker', TimePicker)
Vue.component('DatePicker', DatePicker)
Vue.component('RangePicker', RangePicker)
Vue.component('MonthPicker', MonthPicker)
Vue.component('DownLoad', DownLoad)
Vue.component('OrderList', OrderList)
Vue.component('OrderSearch', OrderSearch)
Vue.use(HighchartsVue)
//阻止 vue 在启动时在console面板生成生产提示
Vue.config.productionTip = false
//允许使用Vue devtools工具
Vue.config.devtools = true
// mount axios Vue.$http and this.$http
Vue.use(VueAxios)
Vue.use(Dialog)
Vue.prototype.$grid = {
  col: {xl: 6, md: 8, sm: 12}, //xxl:5,
  form: {labelCol: {span: 8}, wrapperCol: {span: 16}},
  btn: {labelCol: {span: 0}, wrapperCol: {span: 22, offset: 2}},
  item: {labelCol: {md: 4, xs: 8}, wrapperCol: {md: 20, xs: 16}},
  item2: {labelCol: {xs: 10}, wrapperCol: {xs: 14}}
}
Vue.prototype.$valid = function (refId) {
  return new Promise((resolve, reject) => {
    this.$refs[refId].validate(valid => {
      if (valid) {
        resolve()
      } else {
        reject()
        return false
      }
    })
  })
}

new Vue({
  router,
  store,
  i18n,
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
