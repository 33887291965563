<template>
    <div>
      <a-range-picker @change="onChange" v-model="dateTimes" v-bind="$attrs"  />
    </div>
</template>

<script>
   export default{
     data(){
       return{
         dateTimes:[]
       }
     },
     props:["startTime","endTime"],
     watch:{
       startTime: {
       	handler(newVal) {
       		if(newVal){
       		  this.$set(this.dateTimes,0,moment(newVal))
       		}
       	},
       	deep: true,
       	immediate: true
       },
       endTime: {
       	handler(newVal) {
       		if(newVal){
       		  this.$set(this.dateTimes,1,moment(newVal))
       		}
       	},
       	deep: true,
       	immediate: true
       },
     },
     methods:{
       onChange(date,dataString){
         this.$emit("update:startTime",dataString[0])
         this.$emit("update:endTime",dataString[1])
         this.$emit("change")
       }
     },
     created() {
       
     }
   }
</script>

<style lang="less" scoped>
</style>
