<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-10 15:53:48
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-10 15:53:59
-->
<template>
  <div class="invoice_review">
    <a-modal
      title="审核"
      width="35%"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :footer="null"
      @cancel="visible = false"
      :maskClosable="false"
    >
      <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 10 }">
        <a-form-model-item label="是否通过">
          <a-radio-group v-model="audit_status" @change="onStatusChange">
            <a-radio :value="4"> 通过 </a-radio>
            <a-radio :value="3"> 拒绝 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="statusRadio == 4" label="供应商" prop="supplier" :autoLink="false" ref="supplier">
          <a-select @change="onChange" labelInValue placeholder="请选择供应商" v-model="supplier">
            <a-select-option :value="item.id" v-for="(item, index) in supplierList" :key="index">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="statusRadio == 4" icon="down" label="仓库" :autoLink="false" ref="test">
          <a-select labelInValue placeholder="请选择仓库" @change="handleWarehouseChange" v-model="warehouse">
            <a-select-option :value="item.id" v-for="(item, index) in warehouseList" :key="index">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea v-model="rowData.rejectReason"></a-textarea>
        </a-form-model-item>
      </a-form-model>
      <div class="footer-bts">
        <a-button type="default" @click="visible = false">取消</a-button>
        <a-button type="danger" v-if="statusRadio == 3" @click="onAudit('refuse')">确定</a-button>
        <a-button type="primary" v-if="statusRadio == 4" @click="onAudit('audit')">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'invoice_review',

  data() {
    return {
      confirmLoading: false,
      visible: false,
      statusRadio: 4,
      audit_status: 4,
      rowData: {},
      supplierList: [],
      warehouseList: [],
      supplier: '请选择',
      warehouse: '请选择',
      changeRadio: 1,
      obj: {}
    }
  },

  computed: {},

  created() {},

  methods: {
    show(row) {
      this.visible = true
      this.obj = row
      this.rowData = {}
      this.axios.get('/api/base/system/dictionary/selectByCode/' + '供应商').then((res) => {
        // 获取字典供应商
        this.supplierList = res.body
      })
    },

    onStatusChange(val) {
      this.statusRadio = val.target.value
    },

    onChange(val) {
      this.$refs.supplier.onFieldChange()
      if (val.key == 11) {
        this.changeRadio = 1
      } else {
        this.changeRadio = 2
      }
      this.rowData.supplierName = val.label
      this.rowData.supplierId = val.key
      this.getList()
    },

    getList() {
      let text =  this.changeRadio == 1 ? '菱感仓库' : '舜天仓库' 
      this.axios.get('/api/base/system/dictionary/selectByCode/' + text).then((res) => {
        // 获取字典仓库
        this.warehouseList = res.body
      })
    },

    handleWarehouseChange(val) {
      this.$refs.test.onFieldChange()
      this.rowData.warehouseId = val.key
      this.rowData.warehouseName = val.label
    },

    // 审核
    onAudit(name) {
      let query = {
        id: this.obj.id,
        shippingOrderStatus: name == 'audit' ? 2 : 3,
        remark: this.rowData.rejectReason,
        warehouseId: this.rowData.warehouseId,
        warehouseName: this.rowData.warehouseName,
        supplierId: this.rowData.supplierId,
        supplierName: this.rowData.supplierName
      }
      //接口更新，toExamine已作废
      this.axios.post('/api/order/system/orderShipInfo/toExamine', query).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.rowData = {}
          this.supplier = ''
          this.warehouse = ''
          this.$emit('reload')
          this.visible = false
        } else {
          this.$message.error(res.message)
          this.$emit('reload')
          this.visible = false
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
</style>