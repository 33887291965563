<!--
 * @Description: AKJERRT
 * @Date: 2022-04-06 09:50:10
 * @LastEditTime: 2022-08-15 17:03:43
 * @FilePath: \distributor\src\App.vue
-->
<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
      <initCongirmModal />
      <initModal />
    </div>
  </a-config-provider>
</template>

<script>
  // 初始化弹窗
  import initModal from './components/initModal/initModal.vue'
  // 判断当前设备
  import {AppDeviceEnquire} from '@/utils/mixin'
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
  import initCongirmModal from './components/initCongirmModal/initCongirmModal.vue'

  // 引用cdn还可以这么写  射中ant-design-vue默认为中文
  // import {
  //   locales
  // } from 'ant-design-vue'
  // const zhCN = locales.zh_CN

  export default {
    mixins: [AppDeviceEnquire],
    components: {
      initCongirmModal,
      initModal,
  },
    data() {
      return {
        locale: zhCN,
        key: Date.now(),
      }
    },
    methods: {
      getPopupContainer(el) {
        if (el) {
          return el.parentNode
        } else {
          return document.body
        }
      }
    },
    mounted() {

    }
  }
</script>
