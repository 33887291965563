import { render, staticRenderFns } from "./OrderShipInfoEditModal.vue?vue&type=template&id=70ad3c6f&scoped=true&"
import script from "./OrderShipInfoEditModal.vue?vue&type=script&lang=js&"
export * from "./OrderShipInfoEditModal.vue?vue&type=script&lang=js&"
import style0 from "./OrderShipInfoEditModal.vue?vue&type=style&index=0&id=70ad3c6f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70ad3c6f",
  null
  
)

export default component.exports