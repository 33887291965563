/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const getBillRemind = params => axios({
  url: '/api/order/statements/dealerStatements/selectOverdueCountByDealerId',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})