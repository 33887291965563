<template>
  <div>
     <a-range-picker
      :show-time="{ format: 'HH:mm:ss' }"
      format="YYYY-MM-DD HH:mm:ss"
      :placeholder="['开始时间', '结束时间']"
      @change="onChange"
      @ok="onOk"
    />
  </div>
</template>
<script>
export default {
    data(){
      return{
          list:[]
      }
    },
    watch:{
      list(newVal){
          if (newVal) {
           this.list = newVal
         }else{
           this.list = []
         }
      }
    },
  methods: {
    onChange(value, dateString) {
      this.list = dateString
    },
    onOk(value) {
        let obj = {
            startTime:this.list[0],
            endTime:this.list[1],
        }
      this.$emit('timeSelect',obj)
    },
  },
};
</script>
