import { render, staticRenderFns } from "./SettingItem.vue?vue&type=template&id=026b2cab&scoped=true&"
import script from "./SettingItem.vue?vue&type=script&lang=js&"
export * from "./SettingItem.vue?vue&type=script&lang=js&"
import style0 from "./SettingItem.vue?vue&type=style&index=0&id=026b2cab&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "026b2cab",
  null
  
)

export default component.exports