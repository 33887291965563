/*
 * @Description: AKJERRT
 * @Date: 2022-08-12 14:49:02
 * @LastEditTime: 2022-08-15 10:47:05
 * @FilePath: \linkgap-front\src\components\initCongirmModal\api\api.js
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const getUpgradeRemind = params => axios({
  url: '/api/base/system/sysUpgradeRecord/getUpgradeRemind',
  method: 'post',
  data: JSON.stringify(params),
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})
// 用户阅读系统提醒

export const readUpgradeRemind = params => axios({
  url: '/api/base/system/sysUpgradeRecord/readUpgradeRemind/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})