import { constantRouterMap } from '@/config/router.config'
import { generatorDynamicRouter } from '@/utils/routerUtil'

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
    cacheRouters:[],
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    },
    ADD_CACHEROUTERS:(state,name)=>{
      if(state.cacheRouters.indexOf(name)>=0){return}
      state.cacheRouters.push(name)
    },
    DEL_CACHEROUTERS:(state,name)=>{
      let tmp=state.cacheRouters.indexOf(name)
      if(tmp>=0){
        state.cacheRouters.splice(tmp,1)
      }
    },
  },
  actions: {
    addCacheRouters({ commit }, name) {
      commit('ADD_CACHEROUTERS', name)
    },
    delCacheRouters({ commit }, name) {
      commit('DEL_CACHEROUTERS', name)
    },
    
    /**
     * 构建获取和构建路由和菜单信息
     * @param commit
     * @param data
     * @returns {Promise<any>}
     * @constructor
     */
    GenerateRoutes ({ commit }, data) {
      return new Promise(resolve => {
        generatorDynamicRouter(data).then(routers => {
          commit('SET_ROUTERS', routers)
          resolve()
        })
      })
    }
  }
}

export default permission
