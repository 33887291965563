<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-28 10:07:00
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-10 13:41:31
-->
<template>
<div class=''>
  <a-table :data-source="tableData" :loading="tableLoading" :rowKey="record => record.id" :pagination="page" bordered @change="changeTable">
    <a-table-column title="操作人姓名" data-index="createUser" align="center"></a-table-column>
    <a-table-column title="操作类型" data-index="operationType" align="center">
      <template slot-scope="text">
        {{text | operatingType}}
      </template>
    </a-table-column>
    <a-table-column title="操作内容" data-index="logContent" align="center"></a-table-column>
    <a-table-column title="日志生成时间" data-index="createTime" align="center"></a-table-column>
  </a-table>
</div>
</template>

<script>
const qs = require('qs')
export default {
  name:'',
  data() {
    return {
      tableLoading: false,
      btnsLoading: false,
      tableData: [],
      handle: '',
      searchData: {},
      page: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      selectedRowKeys: [],
      selectedRows: [],
    }
  },

  props: ['orderId'],

  watch: {
      orderId:{
        handler (val) {
        if(val){
          this.orderId = val;
          this.getList()
          this.page.current = 1
        } 
      },
      immediate: true
    }
      
  },
  filters: {
    operatingType (service) {
      const serviceMap = {
        1: '新增',
        2: '编辑',
        3: '删除',
        4: '查询',
        5: '审核',
        6: '其他'
      }
      return serviceMap[service]
    }
  },
  // mounted() {
  //   this.getList()
  // },
  methods: {
    getList(pagination) {
      let obj = {}
      if(pagination){
        obj.pageNumber=pagination.current,
        obj.pageSize=pagination.pageSize,
        obj.orderId=this.orderId
      }else{
        obj.pageNumber=this.page.current,
        obj.pageSize=this.page.pageSize,
        obj.orderId=this.orderId
      }
      this.axios.get(`/api/order/order/orderTrackLog/list?${qs.stringify(obj)}`).then(res => {
        this.tableData = res.body.records
        this.page.total = res.body.total
      })
    },
    
    changeTable(pagination) {
      this.page = pagination
      this.getList(pagination)
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
  }
}
</script>

<style lang='scss' scoped>
</style>