<template>
    <div>
      <a-month-picker :show-time="hasTime" v-model="startTime2"  placeholder="请选择时间" @change="changeStartTime"
         :get-canlender-container="trigger=>trigger.parentNode" v-bind="$attrs">
      </a-month-picker>
    </div>
</template>

<script>
  import moment from 'moment'
   export default {
     data() {
       return {
         startTime2: null,
         format: this.hasTime ? 'YYYY-MM-DD' : 'YYYY-MM'
       }
     },
     // props:['startTime','isNull'],
     props: {
       startTime: {
         type: String
       },
       isNull: {
         type: Boolean,
         default: false
       },
       hasTime: {
         type: Boolean,
         default: false
       }
     },
     watch: {
       startTime(newVal) {
         this.startTime2 = newVal ? moment(newVal) : undefined
       }
     },
     methods:{
       changeStartTime() {
         this.$emit("update:startTime", this.startTime2 ? this.startTime2.format(this.format) : '')
         this.$emit("change")
       },
     },
     created() {
       this.$emit("update:startTime", this.startTime2 ? this.startTime2.format(this.format) : '')
     }
   }
</script>

<style lang="less" scoped>
</style>
