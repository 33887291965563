<template>
  <div>
    <a-range-picker v-model="getDateTime"  @change="onChangeShipDate" />
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    data() {
      return {
        startTime2: this.isNull ? '' : moment().subtract(3, 'months'),
        endTime2: this.isNull ? '' : moment(),
        dateTime: []
      }
    },
    props: {
      startTime: {
        type: String,
        default: ''
      },
      endTime: {
        type: String,
        default: ''
      },
      isNull: {
        type: Boolean,
        default: true
      },
    },
    watch: {
      startTime(newVal, oldVal) {
        this.startTime2 = newVal ? moment(newVal) : undefined
      },
      endTime(newVal, oldVal) {
        this.endTime2 = newVal ? moment(newVal) : undefined
      },
    },

    computed: {
       getDateTime: {
        get() {
          if(this.startTime2 && this.endTime2) {
            return this.dateTime = [this.startTime2, this.endTime2]
          }
          return []
        },
        set(value) {
          this.dateTime = value
        }
      }
    },
   
    methods: {
      onChangeShipDate(date, dateString) {
        this.startTime2 = dateString[0]
        this.endTime2 = dateString[1]
        this.changeStartTime()
        this.changeEndTime()
      },
      changeStartTime() {
        this.$emit("update:startTime", this.startTime2)
      },
      changeEndTime() {
        this.$emit("update:endTime", this.endTime2)
      },
      disabledStartDate(startValue) {
        const endValue = this.endTime2;
        if (!startValue || !endValue) {
          return false;
        }
        return startValue.valueOf() > endValue.valueOf();
      },
      disabledEndDate(endValue) {
        const startValue = this.startTime2;
        if (!endValue || !startValue) {
          return false;
        }
        return startValue.valueOf() >= endValue.valueOf();
      }
    },
    created() {
      this.$emit("update:startTime", this.startTime2)
      this.$emit("update:endTime", this.endTime2)
    }
  }
</script>

<style lang="less" scoped>
</style>
