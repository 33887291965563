<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-04-06 09:51:10
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-31 15:46:33
-->
<template>
  <div>
    <video v-if="type=='video'" class="mainMedia" :src="value" @click="showImage"></video>
    <img v-else class="mainMedia" :onerror="defaultImg" :src="value ? value : defaultImg" @click="showImage" />
    <ImagePreview :type="type" :visible.sync="visible" :url="value"></ImagePreview>
  </div>
</template>

<script>
  import ImagePreview from "./ImagePreview.vue"
  export default {
    data() {
      return {
        visible: false,
        defaultImg:'this.src="'+require('../../assets/errorimg.png')+'"',
      }
    },
    components: {
      ImagePreview
    },
    props: ["value", "type"],
    methods: {
      showImage() {
        this.visible = true
      }
    },
    created() {

    }
  }
</script>

<style lang="less" scoped>
  .mainMedia{
    max-width: 100%;max-height: 100%;overflow: hidden;text-align: center;vertical-align:top
  }
</style>
