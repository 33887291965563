export const ACCESS_TOKEN = 'Access-Token'
export const ACCESS_CODE = 'Access-Code'
export const SIDEBAR_TYPE = 'SIDEBAR_TYPE'
export const DEFAULT_THEME = 'DEFAULT_THEME' //default_theme
export const DEFAULT_LAYOUT_MODE = 'DEFAULT_LAYOUT_MODE' //default_layout_mode
export const DEFAULT_COLOR = 'DEFAULT_COLOR' //default_color
export const DEFAULT_COLOR_WEAK = 'DEFAULT_COLOR_WEAK' //default_color_weak
export const DEFAULT_FIXED_HEADER = 'DEFAULT_FIXED_HEADER' //default_fixed_header
export const DEFAULT_FIXED_SIDEMENU = 'DEFAULT_FIXED_SIDEMENU' //default_fixed_sidemenu
export const DEFAULT_FIXED_HEADER_HIDDEN = 'DEFAULT_FIXED_HEADER_HIDDEN' //default_fixed_header_hidden
export const DEFAULT_CONTENT_WIDTH_TYPE = 'DEFAULT_CONTENT_WIDTH_TYPE' //default_content_width_type
export const DEFAULT_MULTI_TAB = 'DEFAULT_MULTI_TAB' //default_multi_tab

export const CONTENT_WIDTH_TYPE = { //content_width_type
  Fluid: 'Fluid',
  Fixed: 'Fixed'
}
