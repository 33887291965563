import { render, staticRenderFns } from "./RadioChk.vue?vue&type=template&id=539eb56a&scoped=true&"
import script from "./RadioChk.vue?vue&type=script&lang=js&"
export * from "./RadioChk.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "539eb56a",
  null
  
)

export default component.exports