<template>
  <div class="">
    <a-cascader :getPopupContainer="node=>node?node.parentNode:document.body" ref="cascader" v-model="pcd" style="width:100%"
      :options="PCDOptions" :load-data="loadPCDData" :placeholder="placeholder?placeholder:'所在地区'" change-on-select
      @change="changePCD" :allowClear="!noClear" @blur="blurPCD" :disabled="disabled" @search="onSearch"/>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        PCDOptions: [],
        pcd: []
      }
    },
    props: ["provinceId", "cityId", "districtId", "province", "city", "district", "hasArea", "areaId", "area",
      "placeholder","noClear","disabled"
    ],
    watch: {
      provinceId(newVal) {
        this.$set(this.pcd, 1, newVal)
      },
      cityId(newVal) {
        this.$set(this.pcd, 2, newVal)
      },
      districtId(newVal) {
        this.$set(this.pcd, 3, newVal)
      },
      areaId(newVal) {
        this.$set(this.pcd, 4, newVal)
      },
    },
    methods: {
      getPCDById(id, layer) {
        return new Promise((resolve, reject) => {
          this.axios.get(`/api/base/system/baseArea/selectByParentId/${id}`).then(res => {
            let tmpArr = res.body.map(x => {
              if (this.hasArea) {
                x.isLeaf = layer == 3 ? true : false
              } else {
                x.isLeaf = layer == 2 ? true : false
              }
              return x
            })
            resolve(tmpArr)
          }).catch(err => {
            reject(err)
          })
        })
      },
      setPCD() {
        let tmpArr = [this.getPCDById(1, 0)]
        this.pcd = []
        if (this.provinceId) {
          tmpArr.push(this.getPCDById(this.provinceId, 2))
          this.pcd.push(this.provinceId)

          if (this.cityId) {
            tmpArr.push(this.getPCDById(this.cityId, 3))
            this.pcd.push(this.cityId)

            if (this.districtId) {
              this.pcd.push(this.districtId)
              // if (this.areaId) {
              //   tmpArr.push(this.getPCDById(this.districtId, 4))
              //   this.pcd.push(this.areaId)
              // }
            }
          }

          Promise.all(tmpArr).then(resArr => {
            var temp = resArr[0]
            if (this.pcd[0]) {
              let temp1 = temp.filter(x => x.id == this.pcd[0])[0]
              temp1.children = resArr[1]
              if (this.pcd[1]) {
                let temp2 = resArr[1].filter(x => x.id == this.pcd[1])[0]
                temp2.children = resArr[2]
                // if (this.pcd[2] && resArr[3]) {
                //   if (resArr[3]) {
                //     let temp3 = resArr[2].filter(x => x.id == this.pcd[2])[0]
                //     temp3.children = resArr[3]
                //   }
                // }
              }
            }
            this.PCDOptions = [...temp]
          })

        }
      },
      /**
       *  @param {Object} pId 省为0
       * @param {Object} layer 省为 0
       */
      getPCDData(layer, parent, callback) {
        this.axios.get(`/api/base/system/baseArea/selectByParentId/${parent?parent.id:1}`).then(res => {
          let tmpArr = res.body.map(x => {
            if (this.hasArea) {
              x.isLeaf = layer == 3 ? true : false
            } else {
              x.isLeaf = layer == 2 ? true : false
            }
            return x
          })
          if (layer) {
             if(layer < 3){
                parent.children = tmpArr
            }
            this.PCDOptions = [...this.PCDOptions]
          } else {
            this.PCDOptions = tmpArr
          }
          if (typeof callback == 'function') {
            callback()
          }
        }).catch(err => {})
      },
      /**
       * cascader加载数据
       * @param {Object} selectedOptions 当前选择了的对象集合数组
       */
      loadPCDData(selectedOptions) {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        this.getPCDData(selectedOptions.length, targetOption)
      },
      /**
       * 已选择了的对象(返回省市区到父级对象)
       * @param {Object} value
       * @param {Object} selectedOptions
       */
      changePCD(value, selectedOptions) {
        this.$emit("update:provinceId", value[0] ? value[0] : undefined)
        this.$emit("update:province", value[0] ? selectedOptions[0].label : undefined)
        this.$emit("update:cityId", value[1] ? value[1] : undefined)
        this.$emit("update:city", value[1] ? selectedOptions[1].label : undefined)
        this.$emit("update:districtId", value[2] ? value[2] : undefined)
        this.$emit("update:district", value[2] ? selectedOptions[2].label : undefined)
        this.$emit("update:area", value[3] ? selectedOptions[3].label : undefined)
        this.$emit("update:areaId", value[3] ? value[3] : undefined)

      },
      blurPCD(){
         this.$emit("change")
      },
      onSearch() {
        this.$emit("search")
      }
    },
    created() {
      this.getPCDData(0)
    }
  }
</script>

<style lang="less" scoped>
  .holder {
    width: 100%;
    height: 32px;
    background-color: #fff;
    line-height: 32px;
    border: 1px solid #d9d9d9;
    border-radius: 2px
  }
</style>
