<template>
  <div id="userLayout" :class="['user-layout-wrapper', device]">
    <div class="container">
      <div class="top">
        <div class="header">
          <a href="/">
            <img src="~@/assets/dealer_top_banner.png" class="logo" alt="logo">
            <!-- <span class="title">指房向</span> -->
          </a>
        </div>
        <div class="desc">
          <!-- 指房向租赁云平台 专业的一体化管理系统 -->
        </div>
      </div>

      <route-view></route-view>

      <div class="footer">
        <img src="~@/assets/logo.png" class="logo" alt="logo">
        <!-- <div class="links">
          <a href="_self">帮助</a>
          <a href="_self">隐私</a>
          <a href="_self">条款</a>
        </div> -->
        <div class="copyright">
          Copyright &copy; {{ copyright }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteView from './RouteView'
import { mixinDevice } from '@/utils/mixin'
import {copyright} from '../utils/resour'

export default {
  name: 'UserLayout',
  components: { RouteView },
  mixins: [mixinDevice],
  data () {
    return {
      copyright:copyright
    }
  },
  mounted () {
    document.body.classList.add('userLayout')
  },
  beforeDestroy () {
    document.body.classList.remove('userLayout')
  }
}
</script>

<style lang="less" scoped>
  #userLayout.user-layout-wrapper {
    height: 100%;
    background-color: #eff1f4;
    &.mobile {
      .container {
        .main {
          max-width: 368px;
          width: 98%;
        }
      }
    }

    .footer-logo{
      margin-top: 5%;
      margin-left: 10% !important;
    }

    .container {
      width: 100%;
      min-height: 100%;
      //background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
      background: #f0f2f5 url(~@/assets/dog.png) right bottom no-repeat ;
      background-size: 65%;
      //padding: 70px 0 144px;
      background-color: #eff1f4;
      padding: 70px 0 0;
      position: relative;

      a {
        text-decoration: none;
      }

      .top {
        //text-align: center;

        .header {
          //height: 44px;
          //line-height: 44px;

          .badge {
            position: absolute;
            display: inline-block;
            line-height: 1;
            vertical-align: middle;
            margin-left: -12px;
            margin-top: -10px;
            opacity: 0.8;
          }

          .logo {
            // height: 145px;
            vertical-align: top;
            border-style: none;
            //margin-top: -79px;
            margin-left: 10%;
            //width: 365px;
            width: 40%;
            min-width: 365px;
          }

          .title {
            font-size: 33px;
            color: rgba(0, 0, 0, .85);
            font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
            font-weight: 600;
            position: relative;
            top: 2px;
          }
        }
        .desc {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          margin-top: 12px;
          margin-bottom: 80px;
        }
      }

      .main {
        min-width: 260px;
        width: 368px;
        margin: 0 auto;
      }

      .footer {
        // position: absolute;
        //width: 100%;
        // bottom: 0;
        //padding: 0 16px;
        //margin: 0px 0 24px;
       margin: 5% 0 5% 10%;


        .logo{
          padding-bottom: 1%;
        }

        .links {
          margin-bottom: 8px;
          font-size: 14px;
          a {
            color: rgba(0, 0, 0, 0.45);
            transition: all 0.3s;
            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }
        .copyright {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
        }
      }
    }
  }
</style>
