<!--
 * @Author: AKJERRT 1617572569@qq.com
 * @Date: 2022-07-06 10:52:26
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-15 14:32:53
 * @FilePath: \linkgap-front\src\components\initCongirmModal\initCongirmModal.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <a-modal :visible="this.$store.state.common.confirmModal" width="50%" :maskClosable="false" :closable="false">
      <a-badge :offset="[10, 0]">
        <a-icon class="notification" type="notification" />【 {{ this.$store.state.common.confirmModalData.title }}】 &emsp;  {{ this.$store.state.common.confirmModalData.upVersion }}
      </a-badge>
      <br />
      <a-divider />
      <div>
        <a-descriptions>
          <a-descriptions-item>
            <span style="font-size: 12px; color:gray;">更新时间：{{ this.$store.state.common.confirmModalData.createTime }}</span>
          </a-descriptions-item>
        </a-descriptions>
        <p v-html="this.$store.state.common.confirmModalData.content"></p>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="confirmLoading" @click="handleOk">关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { readUpgradeRemind } from './api/api'
export default {
  data() {
    return {
      confirmLoading: false,
    }
  },
  methods: {
    handleOk(e) {
      this.confirmLoading = true
      readUpgradeRemind(this.$store.state.common.confirmModalData.id).then(({ code, body, message }) => {
        // this.$message.success(message)
        this.$store.commit('common/SET_CONFIRM_MODAL_ClOSE')
        this.confirmLoading = false
      })
    },
  },
}
</script>
<style lang="less" scoped>
.notification {
  height: 1rem;
  width: 16px;
}
.article {
  margin-top: 1rem;
}
</style> 