import Vue from 'vue'
import moment from 'moment'
// 已从cdn加载
// import 'moment/locale/zh-cn'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('toMoment', function (dataStr) {
  return moment(dataStr)
})
Vue.filter('buyOrderStatus', function (value) {
  if(value==0){return "待审核"}
  else if(value==1){return "已审核待发货"}
  else if(value==2){return "部分发货"}
  else if(value==-3){return "已发货"}
  else if(value==4){return "已完成"}
  else if(value==5){return "已作废"}
  else{return ''}
})
// 房源总状态状态： 

Vue.filter('auditStatus', function (value) {
  if(value==0){return "待审核"}
  if(value==99){return "未处理"}
  else if(value==1){return "审核通过"}
  else if(value==2){return "审核驳回"}
  else if(value==-1){return "已删除"}
  else if(value==10){return "上架"}
  else if(value==-10){return "下架"}
  else if(value==1){return "审核通过"}
  else if(value==31){return "已刷新"}
  else if(value==32){return "刷新失败"}
  else if(value==33){return "等待刷新"}
  else{return ''}
})


      


Vue.filter('orderStatus', function (value) {
  if(value==0){return "待审核"}
  else if(value==1){return "审核失败"}
  else if(value==2){return "跟进中"}
  else if(value==-3){return "已终止"}
  else if(value==4){return "未成交"}
  else if(value==5){return "已成交"}
  else if(value==6){return "未结佣"}
  else if(value==7){return "已结佣"}
  else if(value==8){return "已释放"}
  else{return ''}
})
//合同状态
Vue.filter('contractStatus', function (value) {
  if(value==0){return "待确认"}
  else if(value==1){return "确认失败"}
  else if(value==2){return "已确认待审核"}
  else if(value==-3){return "审核失败"}
  else if(value==4){return "已审核"}
  else{return ''}
})

 Vue.filter('sourceType', function (value) {
   if(value==1){return "代客下单"}
   else if(value==2){return "自主下单"}
   else{return ''}
 })
 
 Vue.filter('orderSourceType', function (value) {
   if(value==1){return "代客要货"}
   else if(value==2){return "自主要货"}
   else{return ''}
 })
 
 Vue.filter('flagOn', function (value) {
   if(value==0){return "未上架"}
   else{return '已上架'}
 })
 
 Vue.filter('flagUp', function (value) {
   if(value==0){return "未置顶"}
   else{return '已置顶'}
 })
 
 Vue.filter('houseType', function (value) {
   if(value==1){return "独栋公寓"}
   else if(value==2){return "分散式公寓"}
   else{return '小区房源'}
 })
 export const pathWay=[
   {id:0,value:0,label:""},
   {id:1,value:1,label:""},
   {id:2,value:2,label:""},
   {id:3,value:3,label:""},
   {id:4,value:4,label:""},
   {id:5,value:5,label:""},
   {id:6,value:6,label:""},
 ]

 
 Vue.filter('source', function (value) {
   if(value==0){return "系统"}
   else if(value==1){return "微信"}
   else if(value==2){return "IOS"}
   else if(value==3){return "Android"}
   else if(value==4){return "PC"}
   else if(value==5){return "活动"}
   else if(value==6){return "其他"}
   else if(value==7){return "公寓系统"}
   else{return ''}
 })

 Vue.filter('villageSource', function (value) {
   if(value==0){return "后台导入"}
   else if(value==1){return "后台上传"}
   else if(value==2){return "其他"}
   else{return ''}
 })
 
 Vue.filter('brandCategory', function (value) {
   if(value=="BQ1"){return "舒适"}
   else if(value=="BQ2"){return "温馨"}
   else{return ''}
 })
 

 Vue.filter('withdrawAuditStatus', function (value) {
   if(value==0){return "待审核"}
   else if(value==1){return "审核拒绝"}
   else if(value==2){return "审核通过"}
   else if(value==99){return "未处理"}
   else{return ''}
 })
 
 Vue.filter('paymentStatus', function (value) {
   if(value==0){return "待处理"}
   else if(value==1){return "处理中"}
   else if(value==2){return "处理失败"}
   else if(value==3){return "处理成功"}
   else{return ''}
 })
 
 

 Vue.filter('invoiceType', function (value) {
   if(value==0){return "增值税普通发票"}
   else if(value==1){return "增值税专用发票"}
   else{return ''}
 })
 
 Vue.filter('invoiceHeader', function (value) {
   if(value==1){return "个人"}
   else if(value==2){return "单位"}
   else{return ''}
 })